/**
 * 自定义指令 全局配置导出
 * src/directives/index.js
 */
import butt from './directive/butt.js';
import throttleClick from './directive/throttle-click.js';
import languageShow from "@/directives/directive/languageShow";

export default {
    butt,
    throttleClick,
    languageShow,
};
