
// 节流函数
function throttle(func, delay) {
    let lastCall = 0;
    return function(...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func(...args);
    };
}

/**
 * 节流点击 指令
 */
export default {
    beforeMount(el, binding) {
        if (typeof binding.value !== 'function') {
            // console.log('v-throttle-click expects a function as a value');
            return;
        }
        // 使用节流函数包裹传入的方法 节流时间默认为 1000ms 确保点击事件在1秒内只触发一次，防止连续点击多次触发。
        el.__throttledClick__ = throttle(binding.value, 3000);
        el.addEventListener('click', el.__throttledClick__);
    },
    unmounted(el) {
        el.removeEventListener('click', el.__throttledClick__);
        el.__throttledClick__ = null;
    }
};
