<template>
  <head>
    <title>{{ $t('elTag.headerTitle') }}</title>
  </head>
  <div id="app">
    <el-config-provider :locale="locale">
      <!-- 主路由渲染 -->
      <router-view @childClick="upApp"></router-view>
    </el-config-provider>
  </div>

</template>

<script>

import {defineComponent} from 'vue'
import {ElConfigProvider} from 'element-plus'
import en from 'element-plus/dist/locale/en.mjs'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

export default defineComponent({
  name: 'app',
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    // 设置语言
    this.setLanguage();
    // 设置主题
    this.setTheme();
  },
  // 根据子组件传递的值进行切换组件的国际化语言
  methods: {
    upApp(type) {
      if (type === 'theme') {
        // 设置主题
        this.setTheme();
      }
    },
    setLanguage() {
      // 获取浏览器语言 - 语言切换 (zh-CN - 中文，en - 英文)
      let languageStr = navigator.language;
      // 从缓存中获取语言 - 语言切换 (zh-CN - 中文，en-US - 英文)
      let language = window.localStorage.getItem("navigatorLanguage");
      // 如果缓存中有值，就用缓存中的值
      if (language) {
        languageStr = language;
      }
      if (languageStr === 'zh-CN') {
        this.locale = zhCn;
        this.$i18n.locale = 'zh'; // 切换 I18n 语言包 选项式写法
      } else if (languageStr === 'en-US'){
        this.locale = en;
        this.$i18n.locale = 'en'; // 切换 I18n 语言包 选项式写法
      } else {
        this.locale = en;
        this.$i18n.locale = 'en'; // 切换 I18n 语言包 选项式写法
      }
    },
    // 设置主题
    setTheme() {
      // 从缓存中获取主题
      let themeSwitch = window.localStorage.getItem("themeSwitch");
      if (themeSwitch === 'true') {
        // 设置主题 - 暗黑主题 组件内部切换
        let html = document.documentElement
        html.className = 'dark';
        // 引入全局样式 - 暗黑主题
        import ('@/assets/css/app_dark.css');
      } else {
        // 设置主题 - 亮白主题 组件内部切换
        let html = document.documentElement
        html.className = 'light';
        // 引入全局样式 - 亮白主题
        import ('@/assets/css/app_light.css');
      }
    },
  }
})
</script>

<!-- 全局样式 -->
<style>
@import '@/assets/css/fonts.css';
@import '@/assets/css/app.css';
</style>
