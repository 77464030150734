/**
 * 登录页面路由
 * @type {string}
 */
export const loginUrl = '/dashboard/login';
/**
 * 默认首页 C端首页路由 / 售后类型路由 终端客户
 * @type {string}
 */
export const cWebUrl = '/ticket/report';
export const cSetWebUrl = '/ticket/report/set';
/**
 * 默认首页 B端首页路由 经销商
 * @type {string}
 */
export const bWebUrl = '/distributor/report';

/**
 * 后端的请求前缀
 */
export const HTTP_URL_API = process.env.VUE_APP_BASE_API;

/**
 * 文件上传大小限制 60M
 */
export const FILE_SIZE_LIMIT = 1024 * 1024 * 61;

/**
 * 默认主题对象
 */
export const defaultTheme = {
    // 顶部栏样式
    headerSty: {
        // Logo
        logo: true,
        //标题
        title: false,
        // 背景颜色
        backgroundColor: '#ffffff',
        // 文字颜色
        textColor: '#252525',
    },
    // 菜单样式 true=收缩 false=展开
    menuSty: false,
    // 菜单手风琴
    menuAccordion: true,
    // 菜单颜色
    menuColor: {
        // 背景颜色
        backgroundColor: '#232324',
        // 文字颜色
        textColor: '#c2c2c3',
        // 高亮颜色
        activeTextColor: '#1890ff',
    },
    // 动态路由标签
    roueDiv: true,
    // 动态路由标签颜色
    roueColor: {
        // 背景颜色
        roueBackgroundColor: '#ffffff',
        // 文字颜色
        roueTextColor: '#303133',
        // 高亮颜色
        roueActiveTextColor: '#1890ff',
    },
    // 组件页样式
    viewSty: {
        // 列设置与查询框
        viewButton: true,
    }
}

/**
 * 暗黑模式
 */
export const darklyTheme = {
    // 顶部栏样式
    headerSty: {
        logo: true,
        title: false,
        backgroundColor: '#141414',
        textColor: '#d9d9da',
    },
    // 菜单样式 true=收缩 false=展开
    menuSty: false,
    menuAccordion: true,
    menuColor: {
        backgroundColor: '#141414',
        textColor: '#d9d9da',
        activeTextColor: '#1890ff',
    },
    // 动态路由标签
    roueDiv: true,
    // 动态路由标签颜色
    roueColor: {
        roueBackgroundColor: '#181818',
        roueTextColor: '#d9d9da',
        roueActiveTextColor: '#1890ff',
    },
    // 组件页样式
    viewSty: {
        // 列设置与查询框
        viewButton: true,
    }
}

/**
 * 颜色选择器预设
 */
export const colorPicker = [
    '#ff4500',
    '#ff8c00',
    '#ffd700',
    '#90ee90',
    '#00ced1',
    '#1e90ff',
    '#c71585',
    '#000000',
    '#ffffff',
    '#ff69b4',
    '#ff1493',
    '#ff6347',
    '#ff7f50',
]

/**
 * 富文本编辑器 完整模式隐藏部分
 * @type {string[]}
 */
export const defaultExcludeKeys = [
    'todo',
    'fullScreen',
    'group-video',
    'group-image',
    'group-indent',
    'emotion',
    'divider',
    'fullScreen',
]
/**
 * 富文本编辑器 简洁模式隐藏部分
 * @type {string[]}
 */
export const simpleExcludeKeys = [
    'blockquote',
    'through',
    'clearStyle',
    'todo',
    'justifyLeft',
    'justifyRight',
    'group-image',
    'insertVideo',
    'insertTable',
    'codeBlock',
    'undo',
    'redo',
    'fullScreen',
    'justifyCenter',
]

/**
 * 默认图表颜色
 * @type {string[]}
 */
export const chartColor = [
    '#1e90ff',
    '#ff4500',
    '#ff8c00',
    '#ffd700',
    '#90ee90',
    '#037273',
    '#c71585',
    '#000000',
    '#ff69b4',
    '#ff1493',
    '#ff6347',
    '#ff7f50',
    '#c23531',
    '#2f4554',
    '#61a0a8',
    '#d48265',
    '#91c7ae',
    '#749f83',
    '#ca8622',
    '#bda29a',
    '#6e7074',
    '#546570',
    '#87d8a1',
    '#51253b',
    '#59404d',
    '#8a5248',
    '#674d43',
    '#684342',
    '#667b88',
    '#0542a1',
    '#ece2b3',
    '#72a172',
    '#357475',
    '#81737b',
    '#3c0404',
    '#882c5a',
]

/**
 * 图表暗黑模式背景颜色
 */
export const darklyChartColor = '#1d1e1f'
/**
 * 图表正常模式背景颜色
 */
export const normalChartColor = '#ffffff'
