// src/langurage/zh_CN.js

/**
 * 定义中文语言包对象
 */
export default {
    // 通用语言包
    common: {
        // 提示框
        dialog: {
            title: '提示',
            // 默认框
            defaultTitle: '默认框',
            addTitle: '新增框',
            editTitle: '编辑框',
            detailTitle: '详情框',
            selectTitle: '选入框',
            preview: '预览',
            deleteTitle: '数据删除',
            setDataNull: '请先选择后在进行操作!',
            deleteTips: '此操作将永久删除该数据, 是否继续?',
            inquireTips: '售后人员正在发起此售后流程, 您是否同意?',
            // 修改角色权限框
            permissionTitle: '修改角色权限',

            replyTitle: '回复框',
            eventTitle: '新建售后工单事件',
            fillInLogisticsTitle: '填入物流',
            examineCheck: '双击查看',
            clickBox: '点击查看',
            roleTitle: '查看角色权限',
        },
        message: {
            // 提示
            tipsTitle: '提示',
            successTitle: '成功',
            success: '操作执行成功',
            errorTitle: '失败',
            error: '操作执行失败',
            unknownTitle: '未知',
            unknown: '未知状态 请联系管理员',

            addSuccess: '新增成功',
            addError: '新增失败',
            updateSuccess: '修改成功',
            updateError: '修改失败',
            deleteSuccess: '删除成功',
            deleteError: '删除失败',

            networkError: '网络异常, 稍后再试!',
            uploadError: '上传头像图片只能是 JPG/PNG 格式!',
            uploadSizeError: '上传头像图片大小不能超过 2MB!',
            uploadNumError: '文件上传超出限制!',

            uploadFileSizeError: '上传的文件大小不能超过 60MB!',
            uploadFileNumError: '当前上传限制选择 6 个文件，超出限制!',
            uploadFileFormatErr: '上传文件格式不在允许的范围内',

            sortNumber: '排序的值必须为数字',
            dictKeyAndValue: '请输入字典键和字典值',
            isDefault: '请切换 "非主邮箱" 为 "主邮箱" 后会自动取消其他邮箱的主邮箱状态',

            copySuccess: '复制成功',
            remoteLogin: '异地登录',
            remoteLoginMsg: '账号已在其他地方登录, 如不是本人登录请尽快修改密码!',
            imgUploadMgs: '点击可进行图片上传',

            setRoleMgs: '请选择用户所分配的角色',
        },
        // 按钮
        button: {
            home: '首页',
            login: '登录',
            register: '注册',

            search: '查询',
            reset: '重置',
            resetLogin: '重置登录表单?',
            add: '新增',
            save: '保存',

            detail: '详情',
            edit: '编辑',
            delete: '删除',
            deletes: '批量删除',

            modify: '修改',
            confirm: '确认',
            confirmOk: '确认',
            send: '发送',
            back: '返回',
            goBack: '返回',
            cancel: '取消',

            select: '选入',
            permission: '权限',

            progress: '沟通',
            record: '记录',
            care: '转交',
            allocation: '分配',
            appoint: '指定工单',
            receive: '领取工单',
            transfer: '转交工单',
            transfering: '转交中',
            cancelTransfer: '取消转交',

            fillIn: '填入',
            check: '查看',
            checkAll: '查看全部',

            after: '售后',
            reply: '回复',
            close: '关闭',
            ReplayAndClose: '回复并关闭',
            end: '结束',
            submit: '提交',
            addCost: '新增费用',

            accept: '接受',
            refuse: '拒绝',

            option: '选择',
            selectAll: '全选',

            prev: '上一步',
            sign: '签收',

            change: '更改',
            continue: '继续',
            revoked: '撤销',
            share: '分享',
            generate: '生成',
            copy: '复制',
            jump: '跳转',
            restore: '恢复默认',

            resetPass: '重置密码',
            setRole: '分配角色',

            read: '一键已读',

            fillInAccount: '填入账户',
            checkAccount: '查看账户',

            importExcel: '上传RMA模板',
            downloadExcel: '下载RMA模板',
            goTicket: '售后主页',

            uploadFile: '上传文件',
            total: '合计',

            uploadInvoice: '上传发票',
            checkInvoice: '查看发票',
            ticketInvoice: '工单发票',

            mergeTicket: '合并工单',
            reopen: '工单恢复',

            downloadUrl: '固件下载',
            flowCheck: '填入',
        },
        table: {
            noData: '暂无数据',
            number: '序号',
            operation: '操作',
            select: '选择',
            allocation: '分配',
        },
        // 加载提示
        loadingText: '请稍后, 正在加载中...',
        loginExpired: '登录已过期，请重新登录',
    },
    // 下拉框 通用
    pullDown: {
        placeholder: '请选择',
        unknown: '未知',
        noData: '暂无',
        sex: {
            man: '男',
            woman: '女',
            secret: '不设置性别',
            other: '其他',
        },
        userStatus: {
            enable: '在职',
            disable: '离职',
        },
        daterange: {
            start: '开始日期',
            range: '至',
            end: '结束日期',
        },
        resourceType: {
            parent: '目录',
            page: '菜单',
            button: '按钮',
        },
        enable: {
            enable: '启用',
            disable: `未启用`,
        },
        messagePushTime: {
            goTime: '时间推送',
            goPlus: '直接推送',
        },
        messageType: {
            complaint: '客诉通知',
            system: '系统通知',
        },
        isDefault: {
            defaultMail: '主邮箱',
            notDefaultMail: '非主邮箱',
        },
        afterStatus: {
            allocation: '待分配',
            pending: '待处理',
            processing: '处理中',
            completed: '已结束',
            revoked: '已撤销',
            maintain: '维修中',
        },
        expressageStatus: {
            processing: '物流发出-待签收',
            completed: '物流发出-已签收',

            accountNo: '退款账户-待填',
            accountYes: '退款账户-已填',
        },
        careofStatus: {
            processing: '转交中',
            completed: '转交成功',
            refused: '转交被拒',
            cancel: '转交取消',
        },
        afterFromType: {
            privacy: '隐私政策',
            disclaimer: '免责声明',
            terms: '条款与条件',
        },
        result: {
            success: '成功',
            error: '失败',
        },
        setUp: {
            sys: '系统内置',
            drop: '表单下拉',
        },
        whether: {
            yes: '是',
            no: '否',
        }
    },
    // 表格操作
    excel: {
        // 表格的导入
        importTitle: '导入数据',
        download: '下载导入模板',
        importTips: '请上传Excel文件',
        importError: '文件格式不正确, 上传文件只能是 xls/xlsx 格式!',

        // 提示框
        dialog: '导入表格错误提示',
        maxlog: '表格文件最大为10M, 此文件超过最大值, 可分批进行导入!',
        label: '错误信息',

        // 表格的导出
        exportTitle: '表格的导出',
        spuFile: '产品信息-',
    },
    // 设置
    system: {
        train: '列设置',
        frame: '查询框',
    },
    // 标签
    elTag: {
        headerTitle: '支持 - iFi',

        mdTitle: '编辑器',
        editorTitle: '富文本编辑器',
        editorTip: '请输入您的内容...',

        confirmed: '已确认',
        expired: '已过期',
        expiredAff: '流程已结束',
    },
    // 文件
    file: {
        uploadLabel: '附件上传',
        uploadSidLabel: '包含发票?',
        text: '将文件拖拽到这里 或者',
        textEm: '点击上传',
        textTip: '最多可上传6个文件, 单个文件大小不超过60M',

        fromLabel: '工单附件',
        fromText: '附件',

        mgsFileNull: '请先上传附件!',

        table: '附件列表',
        invoiceTable: '工单发票',
        name: '附件名称',
        time: '上传时间',

    },

    // ------ 登录页面 / 主页框架 / 首页 / 个人中心 ------
    // 登录页面语言包
    LoginView: {
        tab1: '账号密码登录',
        login: '登录',
        account: '账号',
        accountPlaceholder: '请输入你的账号',
        password: '密码',
        passwordPlaceholder: '请输入你的密码',

        titleUrl: '导航链接',
        clienteleAfter: '客户售后',
        officialMall: '经销商售后',
        officialWebsite: '售后支持',
        afterPlatform: '服务支持',
    },
    // 主页语言包
    HomeView: {
        // 标题
        title: 'iFi 售后平台',
        home: '平台首页',
        // 语言切换
        language: {
            zh: '中文简体',
            en: 'English'
        },
        languageDialog: {
            title: '语言切换',
            zhContent: '是否将语言切换成简体中文, 是否继续？',
            enContent: '是否将语言切换成英文, 是否继续？',
        },
        itemDropdown: {
            refresh: '刷新页面',
            close: '关闭标签',
            closeLeft: '关闭左侧标签',
            closeRight: '关闭右侧标签',
            closeAll: '关闭全部标签',
            closeOther: '关闭其他标签',
        },
        // 头像下拉菜单
        avatarDropdown: {
            profile: '个人中心',
            setCache: '刷新缓存',
            clearCache: '清除缓存',
            logout: '退出登录'
        },
        // 退出登录
        logOutDialog: {
            title: '登出',
            logoutTips: '是否确认登出账号？',
            logoutSuccess: '退出登录成功!',
            logoutError: '已取消退出登录',
        },
        // 清理缓存
        clearCache: '正在清理缓存, 请稍后',
        error: '对不起，您访问的页面不存在。',
        iconTitle: '图标库',
    },
    // 首页语言包
    HomePageView: {
        user: {
            title: '欢迎',
        },
        dataPresentation: {
            title: '数据展示 - 处理中售后单',
            radio: {
                my: '我的',
                all: '全部',
            },
            proportion: '总数占比',
            allProportion: '所有售后单总数占比',

            threeDayQuantity: '近3天处理中售后单',
            threeDayQuantityRemark: '自己负责的近三天(0-3)待处理/处理中售后单',
            sevenDayQuantity: '近3-7天处理中售后单',
            sevenDayQuantityRemark: '自己负责的近3-7天(=3<=7)待处理/处理中售后单',
            bigSevenDayQuantity: '近7天后处理中售后单',
            bigSevenDayQuantityRemark: '自己负责的近7天后(>7)处理中售后单',
            allQuantity: '处理中售后单总数量',
            allQuantityRemark: '自己负责的待处理/处理中售后单的总数量',

            allThreeDayQuantity: '近3天所有处理中售后单',
            allThreeDayQuantityRemark: '近3天所有待处理/处理中售后单',
            allSevenDayQuantity: '近3-7天所有处理中售后单',
            allSevenDayQuantityRemark: '近3-7天(=3<=7)所有待处理/处理中售后单',
            allBigSevenDayQuantity: '近7天后所有处理中售后单',
            allBigSevenDayQuantityRemark: '近7天后(>7)所有待处理/处理中售后单',
            allAllQuantity: '所有处理中售后单总数量',
            allAllQuantityRemark: '所有待处理/处理中售后单的总数量',
        },
        chart: {
            title: '图表统计 - 月度完成工单图表',
            radio: {
                halfYear: '近六月',
                year: '近一年',
            },
            my: '我的工单完成数',
            all: '所有工单完成数',
        },
        activities: {
            title: '平台通知公告',
        },
        backLog: {
            title: '我的待办',
            description: '暂时还没有数据啦! 新增一条?',
            addButton: '新增待办事项',
            enableTag: {
                pending: '待办中',
                processing: '进行中',
                completed: '已完成',
            },
            significanceTag: {
                mild: '轻度',
                general: '适中',
                important: '紧急',
            },
            titleDialog: {
                addTitle: '新增待办事项',
                updateTitle: '修改待办事项',
            },
            formDialog: {
                title: '待办标题',
                titlePlaceholder: '请输入标题, 最长为16个文字',
                enable: '待办状态',
                enablePlaceholder: '请选择你的待办事项的状态',
                significance: '重要程度',
                content: '待办内容',
                contentPlaceholder: '请输入具体的待办内容',
            },
        }
    },
    // 主页样式抽屉
    StyHome: {
        title: '主页样式',
        // 全局样式
        wholeDivider: '全局样式',
        wholeTheme: '暗黑主题',
        // 顶部栏样式
        headerDivider: '顶部栏样式',
        headerLogo: 'Logo 显示: ',
        headerTitle: '标题 显示: ',
        headerBackgroundColor: '顶部栏背景颜色: ',
        headerTextColor: '顶部栏文本颜色: ',
        // 侧边栏样式
        menuDivider: '侧边栏样式',
        menuSty: '菜单折叠: ',
        menuAccordion: '菜单手风琴: ',
        menuBackgroundColor: '菜单背景颜色: ',
        menuTextColor: '菜单文本颜色: ',
        menuActiveTextColor: '菜单选中颜色: ',
        // 动态路由标签
        roueDivider: '动态路由标签',
        roueDiv: '动态路由标签: ',
        roueBackgroundColor: '路由标签背景颜色: ',
        roueTextColor: '路由标签文本颜色: ',
        roueActiveTextColor: '路由标签选中颜色: ',
        // 组件页样式
        trainFrameTitle: '组件页样式',
        trainFrame: '列设置与查询框: ',
        trainFrameDefault: '列设置与查询框(恢复默认): ',
        trainFrameDefaultButton: '恢复设置',
        // 提示 是否将系统样式恢复默认设置?
        tips: '是否将系统样式恢复默认设置?',

    },
    // 个人中心语言包
    MyView: {
        labelMgs: '最新动态',
        userForm: {
            label: '个人中心',
            title: '欢迎',
            account: '账号',
            accountPlaceholder: '请输入你的账号',
            nickName: '姓名/英',
            nickNamePlaceholder: '请输入你的姓名/英',
            name: '姓名/中',
            namePlaceholder: '请输入你的姓名/中',
            sex: '性别',
            sexPlaceholder: '请选择你的性别',
            role: '角色',
            rolePlaceholder: '请选择你的角色',
            region: '地区',
            regionPlaceholder: '请选择你的地区',
            phone: '手机号',
            phonePlaceholder: '请输入你的手机号',
            identityCard: '代理编号',
            identityCardPlaceholder: '请输入你的代理编号',
            birthDate: '出生日期',
            birthDatePlaceholder: '请选择你的出生日期',
            userStatus: '用户状态',
            userStatusPlaceholder: '请选择用户状态',
            weixin: '微信',
            weixinPlaceholder: '请输入你的微信号',
            qq: 'QQ',
            qqPlaceholder: '请输入你的QQ号',
            email: '邮箱',
            emailPlaceholder: '请输入你的邮箱',
        },

        updatePass: {
            label: '修改密码',
            account: '账号',
            password: '旧密码',
            passwordPlaceholder: '请输入你原来的密码',
            newPassword: '新密码',
            newPasswordPlaceholder: '请输入你的新密码',
            newTowPasswordPlaceholder: '请再次输入你新的密码',
            passwordLength: '长度在 6 到 20 个字符',
            passwordInconsistent: '两次输入密码不一致!',
        }
    },

    // ------ system:系统管理 ------
    // 用户管理
    UserView: {
        userData: '用户数据',
        getAvatar: '生成头像',
        getAvatarIs: '用户英文名不能为空!',

        nameQuery: '姓名',
        nameQueryPlaceholder: '请输入用户姓名(中文/英文)',
        phoneQuery: '手机/邮箱',
        phoneQueryPlaceholder: '请输入手机号或邮箱',
        weixinQuery: '微信/QQ',
        weixinQueryPlaceholder: '请输入微信号或QQ号',

        account: '账号',
        accountPlaceholder: '请输入你的账号',
        password: '密码',
        passwordPlaceholder: '请输入你的密码',
        passwordMgs: '新增用户默认的密码与您的账号保持一致, 后续可自行在个人中心进行修改!',
        passwordLength: '长度在 6 到 20 个字符',
        nickName: '姓名-英文',
        nickNamePlaceholder: '请输入你的姓名-英文',
        name: '姓名-中文',
        namePlaceholder: '请输入你的姓名-中文',
        sex: '性别',
        sexPlaceholder: '请选择你的性别',
        role: '角色',
        rolePlaceholder: '请选择你的角色',
        region: '地区',
        regionPlaceholder: '请选择你的地区',
        phone: '手机号',
        phonePlaceholder: '请输入你的手机号',
        phoneLength: '长度为 11 个字符',
        identityCard: '代理编号',
        identityCardPlaceholder: '请输入你的代理编号',
        birthDate: '出生日期',
        birthDatePlaceholder: '请选择你的出生日期',
        userStatus: '用户状态',
        userStatusPlaceholder: '请选择用户状态',
        weixin: '微信',
        weixinPlaceholder: '请输入你的微信号',
        qq: 'QQ',
        qqPlaceholder: '请输入你的QQ号',
        email: '邮箱',
        emailPlaceholder: '请输入你的邮箱',
        emailError: '请输入正确的邮箱地址',
        regions: '地区权限',
        regionsPlaceholder: '请选择你的地区权限',
        photoUrl: '头像',
        updateUserName: '修改人',
        registerDate: '注册时间',
        updateDate: '修改时间',
        loginDate: '登录时间',
        loginNum: '登录次数',

        resetPassTxt: '确定重置该用户密码吗? 新密码为该用户的账号...',
    },
    // 角色管理
    RoleView: {
        name: '角色名称',
        namePlaceholder: '请输入角色名称',
        nameEn: '角色名称-英文',
        nameEnPlaceholder: '请输入角色名称-英文',
        remark: '角色描述',
        remarkPlaceholder: '请输入角色描述',
        createTime: '创建时间',
        roleMgs: '查询为页面显示的基础权限, 注意工单的查询权限层级!',
    },
    // 菜单管理
    MenuView: {
        parent: '父级菜单',
        parentPlaceholder: '请选择父级',
        name: '名称',
        namePlaceholder: '请输入菜单名称',
        nameEn: '名称_EN',
        nameEnPlaceholder: '请输入菜单名称-英文',
        symbol: '权限符',
        symbolPlaceholder: '请输入权限符, 一级菜单可忽略',
        icon: '图标',
        iconPlaceholder: '请选择菜单图标',
        resourceType: '资源类型',
        href: '路由地址',
        hrefPlaceholder: '请输入路由地址, 三级按钮可忽略',
        sort: '排序',
        sortPlaceholder: '请输入排序, 必须为数字',
        remark: '备注',
        remarkPlaceholder: '请输入备注',
    },
    // 字典管理
    DictView: {
        // 查询条件
        dictNameQuery: '名称',
        dictNameQueryPlaceholder: '请输入字典名称',
        // 表格
        dictCode: '字典代码',
        dictCodePlaceholder: '请输入字典代码',
        dictName: '字典名称',
        dictNamePlaceholder: '请输入字典名称',
        nameEn: '名称(EN)',
        nameEnPlaceholder: '请输入字典名称-英文',
        dictRemark: '备注',
        dictRemarkPlaceholder: '请输入备注',
        createUser: '创建人',
        createUserPlaceholder: '请选择创建人',
        createTime: '创建时间',
        type: '分类',
        typePlaceholder: '请选择分类',

        // 新增/修改/详情
        divider: '字典列表详情',
        dictKey: '字典键',
        dictKeyPlaceholder: '请输入字典键',
        keyEn: '字典键(EN)',
        keyEnPlaceholder: '请输入字典键-英文',
        dictValue: '字典值',
        dictValuePlaceholder: '请输入唯一字典值',
        dictDetailRemark: '备注',
        dictDetailRemarkPlaceholder: '请输入字典备注',
        dictEnable: '是否启用',
        sort: '排序值',
    },
    // 操作日志
    Log: {
        access: '访问日志',
        log: '操作日志',

        name: '日志名称',
        ip: 'IP地址',
        opAddress: '请求地址',
        opBrowser: '浏览器',
        opOs: '设备',
        opTime: '时间',
        opUser: '操作人',
        exeStatus: '状态',
        ok:'成功',
        err:'失败',
        exeStatusMgs: '请选择日志执行状态',

        detailMsg: '日志记录',
        className: '操作类',
        methodName: '操作方法',
        paramJson: '请求参数',
        resultJson: '返回结果',

        errDetailMsg: '异常日志',
        dateTime: '日志日期',
        logPigeonhole: '日志归档',
        noLogPigeonhole: '暂无异常归档日志',
        logNew: '最新异常日志',

        user: '操作人',
        userPlaceholder: '请选择操作人',

        ipPlaceholder: '请输入IP地址',
        sourceDict: '来源模块',
        sourceDictPlaceholder: '请选择来源模块',
        operate: '操作内容',
        operateTime: '操作时间',
        result: '结果',
        resultPlaceholder: '请选择操作结果',

        spendTime: '耗时',
        resultValue: '结果值',
        parameter: '请求参数',
        method: '请求方式',
        url: '请求地址',

        logDetail: '日志详情',
        no: '暂无',

        week: '近一周统计',
        pieChart: '比例统计',
    },
    // 关于我的
    myCentre: {
        user: '个人资料',
        resource: '我的权限',
        log: '我的日志',
    },
    // 通知
    notice: {
        title: '平台站内信',
        message: '站内信',

        tab: '通知',
        classify: '通知分类',
        allNotice: '所有通知',
        messageList: '消息列表',
        myMessage: '我的消息',
    },
    // 系统配置
    sysConfig: {
        category: '类型',
        categoryPlaceholder: '请选择类型',
        configKey: '配置键',
        keyPlaceholder: '请输入配置键',
        configValue: '配置值',
        valuePlaceholder: '请输入配置值',
        remarks: '备注',
        remarksPlaceholder: '请输入备注',
        createTime: '创建时间',
        updateTime: '修改时间',
        createUser: '创建人',
        updateUser: '修改人',

        front: {
            msg: '注意前台背景图片的尺寸(建议是1800 x 400皆可)会影响显示, 可上传保存之后在前台查看效果, 可对图片的长宽做对应的调整!',
            title: '前台标题',
            titleEn: '前台标题 / En',
            txt: '前台简介',
            txtEn: '前台简介 / En',
            img: '前台背景',
            imgEn: '前台背景 / En',
        },
        statistics: {
            title: '可对售后工单统计关于地区工单数据显示进行关联地区ID进行绑定',
        },
        email: {
            alertTitle: '邮件标题配置',

            title: '标题',
            titlePlaceholder: '请输入邮件标题(中文)',
            titleEnPlaceholder: '请输入邮件标题(英文)',
            titleTab: '副标题',
            titleTabPlaceholder: '请输入邮件副标题(中文)',
            titleEnTabPlaceholder: '请输入邮件副标题(英文)',

            alertTitleTow: '邮件内容模板配置',
            titleDrawer: '邮件内容模板配置',
            alertDrawer: '注意: 大括号即为占位符, 部分内容会通过大括号进行动态拼接',
            labelDrawer: '模板内容(中文)',
            labelEnDrawer: '模板内容(英文)',
        },
        emailMgs: {
            REGISTER_CONTENT: '用户注册',
            SET_EMAIL_CONTENT: '邮件更新',

            TICKET_SUBMIT: '工单提交',
            TICKET_END: '售后工单已结束',
            TICKET_NEW: '工单流程更新通知',
            ASSIGN_TICKET: '指定工单通知',
            CAREO_TICKET: '转交工单通知',
            CLIENT_TICKET: '客服回复通知',
            SERVICE_TICKET: '客户回复通知',

            SEND_VERIFY: '发送邮箱验证码',

            SID_SUBMIT: '经销商工单提交通知',
            SID_SERVICE: '经销商留言通知',
            SID_CLIENT: '经销商处理人留言通知',
            SID_END: '经销商工单结束通知',
            SID_ASSIGN: '指定经销商工单通知',
            SID_CAREO: '转交经销商工单通知',
        },
        flow: {
            header: '工单流程配置',
            types: {
                type: '售后类型',
                tui: '退款与退货换货',
                wei: '退回维修',
            },
            formHeader: '模板表单',
            formMgs: '选择左侧模板后，可在下方表单进行设置工单事件自动生成的留言模板内容',
            noMgs: '暂未设置',
            setMgs: '请先在配列表设置该模板完成初始化！',
            setDataMgs: '请先选择模板！',

            tabHeadZh: '标题（中）',
            tabHeadEn: '标题（英）',
            contentZh: '内容（中）',
            contentEn: '内容（英）',
        }
    },
    // 会话管理
    session: {
        title1: '当前会话数',
        title2: '最大签发令牌',
        title3: '1小时内新增',
        title4: '后台/B/C端占比',

        tab1: '后台会话',
        tab2: 'B端会话',
        tab3: 'C端会话',

        avatar: '头像',
        acc: '账号',
        nickname: '昵称',
        loginBrowser: '浏览器',
        loginIp: '登录IP',
        loginOs: '登录设备',
        loginTime: '登录时间',
        tokenNum: '令牌数',

        tokenButton: '令牌列表',
        tokenForceButton: '强退',

        forceListButton: '批量强退',
        force: '会话强退',
        forceMsg: '确认要强制退出该用户吗？',

        expireTime: '有效期(30天)',
        tokenStr: 'Token 令牌',
    },

    // ------ toolModule:基础管理 ------
    // 售后类型管理
    problemView: {
        title: '类型',
        titlePlaceholder: '请输入售后类型',
        titleEn: '英文类型',
        titleEnPlaceholder: '请输入售后类型英文',
        remarks: '售后类型描述',
        remarksPlaceholder: '请输入售后类型描述',
        remarksEn: '英文描述',
        remarksEnPlaceholder: '请输入售后类型英文描述',
        icon: '图标',
        iconPlaceholder: '请选择图标',
        enable: '是否启用',
        enablePlaceholder: '请选择是否启用',
        sortCode: '排序码',
    },
    // 消息公告管理
    MessageView: {
        title: '通知标题',
        titlePlaceholder: '请输入通知标题',
        titleEn: '标题-英',
        titleEnPlaceholder: '请输入通知标题-英文',
        content: '通知内容',
        contentPlaceholder: '请输入通知内容',
        contentEn: '内容-英',
        contentEnPlaceholder: '请输入通知内容-英文',
        type: '通知类型',
        typePlaceholder: '请选择通知类型',
        pushTime: '是否按时间推送',
        pushName: '推送类型',
        pushNamePlaceholder: '请选择推送类型',
        goTime: '推送时间',
        startDate: '开始时间',
        endDate: '结束时间',
        createUser: '创建人',
        createTime: '创建时间',
        enable: '是否启用',
    },
    // 地区管理
    RegionView: {
        title: '地区数据表',
        name: '地区/中',
        namePlaceholder: '请输入地区/中文',
        code: '简称',
        codePlaceholder: '请输入简称',
        enName: '地区/英',
        enNamePlaceholder: '请输入地区/英文',
        remarks: '备注',
        remarksPlaceholder: '请输入备注',
        enable: '是否启用',
    },
    // 邮箱管理
    MailView: {
        buyDitchName: '邮箱名称',
        buyDitchNamePlaceholder: '请选择邮箱名称',
        host: '邮箱服务器',
        hostPlaceholder: '请输入邮箱服务器',
        username: '邮箱地址',
        usernamePlaceholder: '请输入邮箱地址',
        password: '箱授权码',
        passwordPlaceholder: '请输入邮箱授权码',
        port: '端口号',
        portPlaceholder: '请输入端口号',
        protocol: 'SMTP协议',
        protocolPlaceholder: '请输入SMTP协议',
        defaultEncoding: '编码格式',
        defaultEncodingPlaceholder: '请输入编码格式',
        remarks: '备注',
        remarksPlaceholder: '请输入备注',
        createUser: '创建人',
        createTime: '创建时间',
        isDefault: '非主邮箱',
    },
    // B端客户
    clientSideb: {
        code: '编码',
        codePlaceholder: '请输入客户编码',
        name: '名称',
        namePlaceholder: '请输入客户名称',
        abbreviation: '简称',
        abbreviationPlaceholder: '请输入客户简称',
        enable: '启用',
    },
    // 自助服务
    issueRule: {
        kindDict: '服务分类',
        kindDictPlaceholder: '请选择服务分类',
        typeDict: '资源类型',
        typeDictPlaceholder: '请选择资源类型',
        title: '标题',
        titlePlaceholder: '请输入中文/英文标题',
        titleZh: '标题(zh)',
        titleZhPlaceholder: '请输入中文标题',
        titleEn: '标题(en)',
        titleEnPlaceholder: '请输入英文标题',
        contentZh: '内容(zh)',
        contentZhPlaceholder: '请输入中文内容',
        contentEn: '内容(en)',
        contentEnPlaceholder: '请输入英文内容',
        remarks: '备注',
        remarksPlaceholder: '请输入备注',
        createUser: '创建人',
        createTime: '创建时间',
        enable: '启用',
    },
    // 工单消息预设
    flowMessage: {
        category: '预设分类',
        categoryPlaceholder: '请选择预设分类',
        content: '预设内容',
        contentPlaceholder: '请输入预设内容',

        flowRemark: '已开启私人注释消息回复!',
        remark: '私人注释',
        remarkPlaceholder: '请输入该条消息的注释信息...',
    },
    // 币种资料
    currency: {
        selectMgs: '关键字',
        selectMgsPlaceholder: '请输入关键字',

        name: '名称(zh)',
        namePlaceholder: '请输入名称/中文',
        nameEn: '名称(en)',
        nameEnPlaceholder: '请输入名称/英文',
        code: '代码',
        codePlaceholder: '请输入代码（如英镑：GBP，人民币CNY）',
        symbol: '符号',
        symbolPlaceholder: '请输入符号（如：￥）',
    },

    // ------ spuData:产品管理 ------
    // 产品管理
    SpuView: {
        spuNameQuery: '名称',
        spuNameQueryPlaceholder: '请输入产品名称/英文',

        spuCode: '产品编码',
        spuCodePlaceholder: '请输入产品编码',
        spuName: '产品名称',
        spuNamePlaceholder: '请输入产品名称',
        nameEn: '产品-英文',
        nameEnPlaceholder: '请输入产品名称-英文',
        spuImg: '产品图片',
        series: '产品系列',
        seriesPlaceholder: '请选择产品系列',
        function: '产品功能',
        functionPlaceholder: '请选择产品功能',
        specs: '产品规格',
        specsPlaceholder: '请输入产品规格',
        remarks: '产品描述',
        remarksPlaceholder: '请输入产品描述',
        remarksEn: '产品描述-英文',
        remarksEnPlaceholder: '请输入产品描述-英文',
        createUser: '创建人',
        createUserPlaceholder: '请选择创建人',
        createTime: '创建时间',
        enable: '启用状态',
    },
    // 产品系列管理
    SeriesView: {
        nameQuery: '系列名称',
        nameQueryPlaceholder: '请输入系列名称/英文',

        barCode: '系列编码',
        barCodePlaceholder: '请输入系列编码',
        name: '系列名称',
        namePlaceholder: '请输入系列名称',
        nameEn: '系列-英文',
        nameEnPlaceholder: '请输入系列名称-英文',
    },
    // 产品功能管理
    FunctionView: {
        nameQuery: '功能名称',
        nameQueryPlaceholder: '请输入功能名称/英文',

        barCode: '功能编码',
        barCodePlaceholder: '请输入功能编码',
        name: '功能名称',
        namePlaceholder: '请输入功能名称',
        nameEn: '功能-英文',
        nameEnPlaceholder: '请输入功能名称-英文',
    },

    // ------ afterTicket:售后工单管理 ------
    // 售后工单管理
    AfterSpu: {
        viewInvoiceNo: '暂无发票',
        viewInvoice: '查看发票',
        invoice: '工单发票',
        cTicketMessage: 'C端售后工单信息',
        cUserMessage: '客户售后信息',

        sourceDictName: '来源',
        sourceDictNamePlaceholder: '请选择来源类型',
        afterOrderNumber: '售后单号',
        afterOrderNumberPlaceholder: '请输入售后单号',
        createDay: '工单计时',
        createDayUnit: '天',
        problemTitle: '售后类型',
        problemTitlePlaceholder: '请选择售后类型',
        spuName: '产品',
        spuNamePlaceholder: '请选择产品',
        function: '产品功能',
        functionPlaceholder: '请选择产品功能',
        series: '产品系列',
        seriesPlaceholder: '请选择产品系列',

        familyName: '客户',
        familyNamePlaceholder: '请输入客户姓名',
        familyPlaceholder: '请选择客户',
        summary: '问题总结',
        summaryPlaceholder: '请输入售后问题总结',
        email: '客户邮箱',
        emailPlaceholder: '请输入客户邮箱',
        spuSequence: '序列号',
        spuSequencePlaceholder: '请输入产品序列号',
        buyTime: '购买日期',
        buyTimePlaceholder: '请选择购买日期',
        buyDitchName: '购买渠道',
        buyDitchNamePlaceholder: '请选择购买渠道',
        ditchRemarks: '渠道备注',
        ditchRemarksPlaceholder: '请输入渠道备注',
        region: '地区',
        regionPlaceholder: '请选择地区',
        prove: '购买证明',
        provePlaceholder: '请输入购买证明',
        videoUrl: '视频地址',
        videoUrlPlaceholder: '请输入视频地址',
        detail: '问题细节',
        detailPlaceholder: '请输入问题细节',

        remarks: '工单备注',
        remarksPlaceholder: '请输入工单备注',
        createTime: '创建时间',
        createDate: '创建日期',
        revampTime: '修改时间',
        finishTime: '完成时间',
        revokedTime: '撤销时间',
        disposeUser: '处理人',
        alterUser: '转交处理人',
        disposeUserPlaceholder: '请选择处理人',
        disposeRegion: '处理人所属地区',
        disposeRole: '处理人所属角色',
        afterStatus: '工单状态',
        afterStatusPlaceholder: '请选择工单状态',

        // 按钮提示信息
        receiveTxt: '确定领取该售后工单吗?',
        transferTxt: '确定取消转交该售后工单吗?',
        disposeUserError: '处理人不能为空!',
        alterUserError: '被转交人不能为空!',
        reopenTitle: '工单状态恢复',
        reopenTxt: '是否将此工单进重新打开进行处理？',

        // 指定 / 转交 标题
        appointTitle: '进行指定工单',
        transferTitle: '进行转交工单',

        // 详情 / 修改 / 新增 页面
        tagOne: '售后工单 - 产品信息',
        tagTwo: '售后工单 - 处理人',
        tagThree: '售后工单 - 详情信息',

        // 进度
        flow: {
            title: '产品售后单流程: ',
            user: '用户: ',
            service: '客服: ',
            summarize: '概述: ',
            describe: '描述: ',
            reference: '参考图片',

            // 流程物流信息查询
            fillInLogistics: '填入物流',
            ditchStatus: '售后状态',
            ditchStatusPlaceholder: '请选择售后状态',
            logistics: '物流公司',
            logisticsPlaceholder: '请输入物流公司',
            logisticsNumber: '物流单号',
            logisticsNumberPlaceholder: '请输入物流单号',
            sendoutDate: '发出日期',
            sendoutDatePlaceholder: '请选择发出日期',
            remarks: '物流备注',
            remarksPlaceholder: '请输入物流备注',

            // 新建工单事件
            event: '新建售后工单事件',
            ditchName: '售后类型',
            ditchNamePlaceholder: '请选择售后类型',
            newDitchStatus: '流程状态',
            newDitchStatusPlaceholder: '请选择流程状态',
            newRemarks: '流程备注',
            newRemarksPlaceholder: '请输入流程备注',

            // 回复弹出框
            formTitle: '概述',
            formTitlePlaceholder: '请输入概述',
            formDescribe: '描述',
            formDescribePlaceholder: '请输入描述',
            uploadTitle: '图片上传',

            formTitleError: '请输入概述以及描述!',
            endTag: '该条售后工单已结束',
        },

        // 事件记录与成本
        affair: {
            event: '事件与记录',
            eventFormTitle: '流程数据表单',
            addEvent: '新增流程数据',
            finishTxt: '此操作将结束该流程, 是否继续?',

            cost: '成本与费用',
            costFormTitle: '成本与费用表单',
            addCost: '新增成本与费用',
            upCost: '修改成本与费用',

            // 事件记录
            ditchStatus: '流程状态',
            ditchStatusPlaceholder: '请选择流程状态',
            expressage: '物流公司',
            expressagePlaceholder: '请输入物流公司',
            expressageNumber: '物流单号',
            expressageNumberPlaceholder: '请输入物流单号',
            numberPlaceholder: '请选择物流单号',
            // sendoutDate: '发出/签收日期',
            sendoutDate: '日期',
            // expressageStatus: '物流状态',
            expressageStatus: '标签',
            expressageStatusPlaceholder: '请选择物流状态',

            affairNumber: '事件编码',
            ditchName: '事件类型',
            ditchNamePlaceholder: '请选择事件类型',
            endTag: '该条流程已结束',

            sendoutDateNew: '发出日期',
            sendoutDateNewPlaceholder: '请选择日期',
            signDate: '签收日期',
            signDatePlaceholder: '请选择日期',
            freight: '运费金额',
            freightPlaceholder: '请输入运费金额',

            // 成本与费用
            ditchNameStr: '费用类型',
            ditchNameStrPlaceholder: '请选择费用类型',
            money: '金额',
            moneyPlaceholder: '请输入金额',
            currencyName: '币种',
            currencyNamePlaceholder: '请选择币种',

            costType: '成本分类',
            ticketIncidents: '工单事件-成本与费用',
            ticketOther: '其他工单-成本与费用',
            supplement: '此费用为补充工单费用, 与售后工单事件无关...',

            remarks: '备注',
            remarksPlaceholder: '请输入备注',
            createTime: '创建时间',
        },

        share: {
            title: '售后工单分享',
            shareTime: '有效期',
            threeDay: '三天有效',
            sevenDay: '七天有效',
            thirtyDay: '三十天有效',
            permanent: '永久有效',
            shareLink: '分享链接',
        },

        // 合并组件
        merge: {
            title: '合并工单',
            mergeTitle: '主工单详情',
            mergeTitle1: '选择合并的子工单',

            subTitle: '子工单详情',
            subTitleDetail: '点击可查看关联工单',

            mergeTip: '取消合并',
            mergeTipTxt: '是否确认将此工单取消合并？',
            mergeTip1: '工单合并',
            mergeTipTxt1: '是否确认将选中的工单合并到当前工单？',
            mergeTip2: '工单提示',
            mergeTipTxt2: '此工单已被合并，可跳转至主工单进行查询和同步后续售后!',
        }
    },
    // 售后工单转交记录
    Careof: {
        tisTxt: '转交人和被转交人不能相同!',
        afterOrderNumber: '售后单号',
        afterOrderNumberPlaceholder: '请输入售后单号',
        problemTitle: '售后类型',
        problemTitlePlaceholder: '请选择售后类型',
        spuName: '产品名称',
        spuNamePlaceholder: '请选择产品名称',
        function: '产品功能',
        functionPlaceholder: '请选择产品功能',
        series: '产品系列',
        seriesPlaceholder: '请选择产品系列',

        familyName: '客户姓名',
        familyNamePlaceholder: '请输入客户姓名',
        buyDitchName: '购买渠道',
        buyDitchNamePlaceholder: '请选择购买渠道',
        region: '地区',
        regionPlaceholder: '请选择地区',
        agoUser: '转交人',
        agoUserPlaceholder: '请选择转交人',
        nowUser: '被转交人',
        nowUserPlaceholder: '请选择被转交人',
        careofStatus: '转交状态',
        careofStatusPlaceholder: '请选择转交状态',

        summary: '问题总结',
        disposeUser: '用户转交',
        createTime: '转交时间',
        revampTime: '处理时间',

        transferTxt: '确定取消转交该售后工单吗?',
        receiveTxt: '确定接受转交该售后工单吗?',
        refuseTxt: '确定拒绝转交该售后工单吗?',

        // 详情
        afterTitle: '售后工单转交',

        // 发起售后工单-转交申请
        transferTitle: '发起售后工单-转交申请',
        // 售后工单-正在转交中
        transferingTitle: '售后工单-正在转交中',
        // 售后工单-转交成功(工单处理人已更换)
        transferSuccessTitle: '售后工单-转交成功(工单处理人已更换)',
        // 售后工单-转交被拒(被转交人拒绝转交工单)
        transferRefusedTitle: '售后工单-转交被拒(被转交人拒绝转交工单)',
        // 售后工单-转交已被转交人取消
        transferCancelTitle: '售后工单-转交已被转交人取消',
        // 售后工单-转交结果
        transferResultTitle: '售后工单-转交结果',
        // 售后工单-转交结束
        transferEndTitle: '售后工单-转交结束',
    },
    // 图表统计
    SpuChart: {
        title: '产品工单图表',
        monthSpu: {
            name: '产品月份客诉率',
            selectData: '月份',
            selectDataPlaceholder: '请选择月份',
            number: '条数',
            numberPlaceholder: '请输入条数',
            numberList:{
                five: '5条',
                ten: '10条',
                fifteen: '15条',
                twenty: '20条',
            },
            afterQuantity: '工单数量',

            daterange: '日期',
            spu: '产品',
        },
        monthAfter: {
            name: '月份工单图表',
            selectData: '年份',
            selectDataPlaceholder: '选择查询年份',
        },
        regionSpu: {
            name: '地区工单图表',
            selectData: '年份',
            selectDataPlaceholder: '选择查询年份',
            region: '地区',
            regionPlaceholder: '请选择地区',
        },
    },
    // 售后看板
    Board: {
        boardTitle: '售后处理中工单数据统计',

        threeDayQuantity: '近 1 ≤ 3 天工单数',
        sevenDayQuantity: '近 3 ≤ 7 天工单数',
        bigSevenDayQuantity: '近 7 ≤ 15 天工单数',
        bigFifteenDayQuantity: '近 15 ≤ 30 天工单数',
        monthOneDayQuantity: '近 1 ≤ 2 月工单数',
        allQuantity: '处理中总工单数',

        allProportion: '售后单未处理总数占比: ',
    },

    // ------ clientView:客户售后/查询 ------
    // 客户售后流程表单页面
    Clientele: {
        header: '支持',

        afterProblem: '售后类型',
        customer: '客户信息',
        spuMessage: '售后详情',
        ticketMessage: '售后信息',


        selectSpu: '选择产品',
        fillIn: '填写信息',
        afterSubmit: '工单提交',

        // 售后工单填写表格
        spuName: '产品名称',
        spuPlaceholder: '请选择产品',
        function: '产品功能',
        series: '产品系列',
        remarks: '描述',
        cardHeader: '请填写售后工单',

        name: '名',
        namePlaceholder: '请输入你的名字',
        family: '姓',
        familyNamePlaceholder: '请输入你的姓氏',
        email: '邮箱',
        emailPlaceholder: '请输入你的邮箱',
        emailError: '请输入正确的邮箱地址',
        spuSequence: '序列号',
        spuSequencePlaceholder: '请输入产品序列号',
        buyTime: '购买日期',
        buyTimePlaceholder: '请选择购买日期',
        buyDitchName: '购买渠道',
        buyDitchNamePlaceholder: '请选择购买渠道',
        ditchRemarks: '渠道备注',
        ditchRemarksPlaceholder: '请输入渠道备注',
        region: '地区',
        regionPlaceholder: '请选择地区',
        summary: '问题总结',
        summaryPlaceholder: '请输入售后问题总结',
        detail: '问题细节',
        detailPlaceholder: '请输入问题细节',
        prove: '购买证明',
        provePlaceholder: '请输入购买证明',
        videoUrl: '视频地址',
        videoUrlPlaceholder: '请输入产品问题相关的视频链接',
        uploadTitle: '工单附件',
        andCheck: '本人同意接收iFi的产品资讯及促销活动',
        check: '请勾选上面三项',
        checkMgs: '请阅读售后的政策与条款',
        emailNoNll: '邮箱验证码不能为空!',

        // 售后工单提交页面
        afterSubmitTitle: '售后工单通知',

        afterSubmitSuccess: '工单已提交',
        afterSubmitSuccessButton: '去查看',

        afterSubmitError: '工单提交失败',
        afterSubmitErrorTips: '网络异常, 提交出错请稍后重新提交',

        // 客户 v2.0 更新
        iFiHeader: '选择售后问题',

        help: '获取你所需的协助',
        contact: '请告诉我们一些具体信息，我们会为你提供适用的解决方案。你可以通过以下等方式联系我们。',

        afterType: '售后类型',
        selectProduct: '选择产品',
        product: '售后产品',
        afterOrder: '售后工单',
        afterTypeTips: '请查看你支持的选项',

        afterResult: '结果通知',

        verifyCode: '验证码',
        verifyCodePlaceholder: '请输入验证码',
        clickCode: '点击可刷新验证码!',
        emailVerifyCode: '邮箱验证码',
        emailVerifyCodePlaceholder: '请输入邮箱验证码',
        CodeTime: '邮箱验证码30分钟内有效, 过期需要重新获取!',
        goEmailCode: '验证码通过可点击发起邮箱验证!',
        getEmailCode: '发送',
        emailNull: '邮箱为空或者邮箱格式不正确!',
        verifyCodeNull: '验证码不能为空!',
        emailNo: '邮箱跟发送验证码的邮箱地址不一致!',
        codeIsNull: '请先获取验证码在进行提交工单!',

        firmwareStr: '产品的固件是否更新到最新?',
        mobileStr: '是否在其他的Android/PC设备上尝试过?',
        driverStr: '您是否下载并安装了正确的iFi驱动程序(仅限PC/Android)?',
    },
    // 客户查询售后工单页面
    ClienteleQuery: {

        // help: '获取你所需的协助',
        // contact: '可通过下方的售后工单号查询售后工单信息, 进行售后工单的处理方式联系我们, 我们将尽快为您解决问题, 感谢您的支持!',

        header: 'iFi 售后工单查询',
        afterOrderNumber: '售后单号',

        // 工单信息
        spuName: '产品',
        function: '功能',
        series: '系列',

        divider: '工单详情',
        problemTitle: '售后类型',
        name: '客户',
        email: '邮箱',
        spuSequence: '序列号',
        buyTime: '购买日期',
        buyDitchName: '购买渠道',
        ditchRemarks: '渠道备注',
        region: '售后地区',
        summary: '问题总结',
        detail: '问题细节',
        afterStatus: '状态',
        disposeUser: '处理人',
        createTime: '创建时间',

        // 物流
        ditchStatus: '售后状态',
        ditchStatusPlaceholder: '请选择售后状态',
        logistics: '物流公司',
        logisticsPlaceholder: '请输入物流公司',
        logisticsNumber: '物流单号',
        logisticsNumberPlaceholder: '请输入物流单号',
        sendoutDate: '发出日期',
        sendoutDatePlaceholder: '请选择日期',
        signDate: '签收日期',
        signDatePlaceholder: '请选择日期',
        remarks: '备注',
        remarksPlaceholder: '请输入备注',

        flow: '处理进度',
        reference: '参考图片',
        fillInLogistics: '物流信息',
        formTitle: '标题',
        formTitlePlaceholder: '请输入概述',
        formDescribe: '描述',
        formDescribePlaceholder: '请输入描述',
        uploadTitle: '图片上传',
        reply: '售后工单回复',

        // 提示
        queryNull: '查询参数为空',
        finishTxt: '此操作将结束该条工单 是否继续?',
        afterEnd: '售后订单已结束',
        revokedTxt: '此操作将撤销该条工单 是否继续?',
        revokedEnd: '售后订单已撤销',

        // 工单流程框
        flowTitle: '工单状态',

        waitDescription: '等待工单分配',
        waitDisposeDescription: '等待客服处理',
        disposeDescription: '客服处理中',
        endDescription: '售后工单已结束',
        revokedDescription: '用户已撤销工单',

        finishTitle: '工单完成',
        finishDescription: '售后工单已完成',

        refundTitle: '退款信息',
        refund: '退款账户',
        refundNoll: '退款账户不能为空!',
        refundPlaceholder: '请填入您的退款账户, 例如: 招商银行 / 878548569654785',
    },
    // 分享页面工单
    Share: {
        invalid: '该链接已失效, 请重新生成工单分享链接',
        error: '该链接查询异常',
    },
    // 用户工单组件信息
    UserTicket: {
        title: '用户信息',
        total: '工单总数',
        open: '正在进行',
        closed: '已完成数',
    },

    // ------ sidebAfter:经销商工单 ------
    // 经销商工单页面
    sidebAfter: {
        setDate: '工单日期',
        afterOrderNumber: '售后单号',
        sidebName: '经销商',
        sidebPlaceholder: '请输入经销商编码',
        sidebNamePlaceholder: '请选择经销商用户',
        email: '邮箱',
        emailPlaceholder: '请输入邮箱',
        buyDitchName: '购买渠道',
        buyDitchNamePlaceholder: '请选择购买渠道',
        channel: '渠道说明',
        channelPlaceholder: '请输入渠道说明',
        regionName: '地区',
        regionNamePlaceholder: '请选择地区',
        salesManager: '销售员',
        salesManagerPlaceholder: '请输入销售员',
        summary: '问题描述',
        summaryPlaceholder: '请输入问题描述',
        remarks: '工单备注',
        remarksPlaceholder: '请输入工单备注',
        createTime: '创建时间',
        revampTime: '修改时间',
        finishTime: '结束时间',
        revokedTime: '撤销时间',
        disposeUser: '处理人',
        disposeUserPlaceholder: '请选择处理人',
        afterStatus: '状态',

        getSpu: '选入产品',
        spuName: '产品',
        spuNamePlaceholder: '请选择产品',
        functionName: '产品功能',
        seriesName: '产品系列',
        quantity: '数量',
        spuSequence: '序列号',
        // spuSequencePlaceholder: '请输入序列号,多个则按 / 分隔,需与数量保持一致',
        spuSequencePlaceholder: '请输入产品序列号',
        spuSequenceRepetition: '序列号重复',
        buyTime: '购买日期',
        detailRemarks: '备注',
        detailRemarksPlaceholder: '请输入备注',

        spuNumStr: '产品数量：{num} 台',

        InvoiceList: '发票管理',
        InvoiceNo: '无需发票',

        rules: {
            sideb: '经销商不能为空',
            email: '邮箱不能为空',
            salesManager: '销售员不能为空',
            region: '地区不能为空',
            buyDitch: '购买渠道不能为空',
            channel: '渠道说明不能为空',
            summary: '问题描述不能为空',
        },

        message: {
            tableNull: '售后产品列表存在空值, * 项为必填项!',
            spuSequenceErr: '产品序列号与产品的数量未保持一致!',
        },

        tab: {
            flow: '工单与进度',
            event: '事件与记录',
            cost: '成本与费用',
            detail: '工单详情',

            title: '经销商工单: ',
        },
        // {{ $t('sidebAfter.flow.flowTitle') }}
        flow: {
            flowTitle: '售后处理进度',
            flowText: '经销商',
            leaveTitle: '留言回复',
            leaveText: '此工单已结束, 感谢您的反馈',
        },
    },
    // 经销商工单的开放页
    fromSide: {
        choiceTxt: '请选择',
        sideTxt: '经销商售后工单',
        selectSpu: '请先选择产品',

        sideTitle: '经销商售后工单',
        tableTitle: '展开售后产品详情',

        message: {
            spuRepetition: '该产品重复, 可新增其产品的售后数量!',
        },
    },
    // 自助服务开放页面
    issueClientele: {
        imgTitle: '获取你所需的服务支持协助',
        imgTxt: '获得有关你的 iFi 产品的最新信息，包括自助服务文档和产品保障以及售后维修信息等等, 以解决您遇到的问题。',

        title: '您可以查询以下服务信息',
        issue: {
            title: '自助服务',
            contact: '- 访问我们的知识库、查阅常见问题解答',
            contact1: '- 致力于为您提供更便捷、高效的自助服务',
            contact2: '- 满足您的需求并解决您的问题',
            button: '点击查询',
        },
        clientele: {
            title: '在线支持',
            contact: '- 线上技术服务解决产品使用中的问题',
            contact1: '- 您可以根据产品出现的问题提交工单',
            contact2: '- 售后客服人员会在第一时间为您解决',
            button: '点击咨询',
        },

        issueTitle: '搜索更多主题',
        issuePlaceholder: '搜索支持',
        all: '全部',
        cardTitle: '支持文档',
        linkUrl: '相关链接',
    },

    // ------ 工单图表 ------
    chart: {
        productPeak: {
            week: '选择周: ',
            num: '客诉数: ',
            currentWeek: '当前周',
            lastWeek: '上一周',
            nextWeek: '下一周',
        },
        ticketTime: {
            dateStr: '日期范围: ',
            all: '工单总数',
            avg: '平均耗时',
            allTime: '工单总耗时',
            day: '天',
            single: '单',
        }
    },

    // ------ 前台登录 ------
    auth: {
        loginTitle: '欢迎回到 iFi',
        registerTitle: '加入 iFi 即刻享受',

        namePlaceholder: '请输入你的昵称',
        accountPlaceholder: '请输入你的邮箱账号',
        passwordPlaceholder: '请输入你账户的密码',
        sendAuthVerifyMailPlaceholder: '请输入你的邮箱验证码',
        sendButton: '发送验证码',
        userType: '我是',
        userC: '客户',
        userB: '经销商',

        login: '登录',
        register: '注册',

        userDown:'个人中心',
        cloeDown:'清除缓存',
        logOutDown:'退出登录',

        txt: '或者',
        getTxt: '已有 iFi 帐户？',
        getLink: '去登录',
        setTxt: '开始你的 iFi 帐户。',
        setLink: '注册一个账号',
        passTxt: '忘记了密码？',
        passLink: '重置密码',

        loginOk: '登录成功',
        loginError: '登录失败',
        loginNotError: '用户未登录,请先登录!',

        authDrawer: '我的信息',
        ticketDrawer: '工单记录',
        userTypeLabel: '用户类型',
        userTag1: '后台用户',
        userTag2: '客户',
        userTag3: '经销商',
        userTag: '未知',
        switchBack: '切换后台',
    }
}
