// --------- 系统相关 -----------
import {HTTP_URL_API} from "@/enumbag/StyleEnum";
import {i18n} from '@/main';
import {ElMessage} from "element-plus";

/**
 * 根据类型获取对应的弹窗/抽屉的标题
 * @param type
 * @returns {string|string}
 */
export function isTypeGetTitle(type) {
    if (type === '') return i18n.global.t('common.dialog.defaultTitle')
    if (type === 'add') return i18n.global.t('common.dialog.addTitle')
    if (type === 'edit') return i18n.global.t('common.dialog.editTitle')
    if (type === 'detail') return i18n.global.t('common.dialog.detailTitle')
}

/**
 * 设置系统最大化 全屏
 * @param maxHome
 */
export function setSysMaximize(maxHome) {
    if (maxHome) {
        let docElm = document.documentElement;
        //W3C
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        }
        //FireFox
        else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        }
        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
        //IE11
        else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        // 退出全屏
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}

/**
 * 根据容器ID设置系统最大化 全屏
 * @param maxHome 是否最大化
 * @param id 容器ID
 */
export function setSysMaximizeById(id, isMaximize) {
    let elem = document.getElementById(id);
    if (isMaximize) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        // 退出全屏
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}

/**
 * 查询系统国际化语言 (支持: 中文/英文)
 * 语言枚举: 浏览器语言/储存本地语言/后端枚举语言/SQL判断语言 必须一致
 * @returns {string} 返回语言 zh-CN_中文 en-US_英文 默认英文
 */
export function getSysLanguage() {
    // 获取浏览器语言 - 语言切换 (zh-CN - 中文，en - 英文)
    let languageStr = navigator.language;
    // 从缓存中获取语言 - 语言切换 (zh-CN - 中文，en-US - 英文)
    let language = window.localStorage.getItem("navigatorLanguage");
    // 如果缓存中有值，就用缓存中的值
    if (language)  languageStr = language;
    // 中文_zh-CN 英文_en-US
    if (languageStr === 'zh-CN') {
        return 'zh-CN';
    } else if (languageStr === 'en-US'){
        return 'en-US';
    } else {
        return 'en-US';
    }
}

/**
 * 设置系统日期格式 (支持: 中文/英文)
 * @returns {string}
 */
export function formatByLanguage() {
    if (getSysLanguage() === 'zh-CN') {
        return 'YYYY-MM-DD';
    } else {
        return 'DD-MM-YYYY'
    }
}

/**
 * 设置系统主题 (支持: 深色/浅色)
 * @returns {boolean} 返回主题 true-深色 false-浅色
 */
export function getSysTheme() {
    let themeSwitch = window.localStorage.getItem("themeSwitch");
    if (themeSwitch === 'true') {
        return true;
    } else {
        return false;
    }
}

/**
 * 通过表格流数据并导出Excel
 * @param res 请求响应的文件流
 * @param name 文件名前缀
 */
export function setExportExcel(res, name) {
    // console.log(res.headers);
    // 文件名前缀 + 时间戳 + 文件后缀
    // let suffix = res.headers['content-disposition'].split('.')[1];
    let suffix = 'xlsx';
    let fileName = name + new Date().getTime() + '.' + suffix;
    let blob = new Blob([res.data], {type: "application/vnd.ms-excel;charset=UTF-8"});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        let objectUrl = (window.URL || window.webkitURL).createObjectURL(blob)
        let downFile = document.createElement('a')
        downFile.style.display = 'none'
        downFile.href = objectUrl
        downFile.download = fileName // 下载后文件名
        document.body.appendChild(downFile)
        downFile.click()
        document.body.removeChild(downFile) // 下载完成移除元素
        window.URL.revokeObjectURL(objectUrl)   // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
    }
}

/**
 * 通过文件流下载文件
 * @param res 请求响应的文件流
 */
export function setDownloadFile(res) {
    // 时间戳 + 文件后缀
    let suffix = res.headers['content-disposition'].split('.')[1]
    let fileName = new Date().getTime() + '.' + suffix;
    let blob = new Blob([res.data], {type: "application/octet-stream"});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        let objectUrl = (window.URL || window.webkitURL).createObjectURL(blob)
        let downFile = document.createElement('a')
        downFile.style.display = 'none'
        downFile.href = objectUrl
        downFile.download = fileName // 下载后文件名
        document.body.appendChild(downFile)
        downFile.click()
        document.body.removeChild(downFile) // 下载完成移除元素
        window.URL.revokeObjectURL(objectUrl)   // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
    }
}

/**
 * 下载Excel导入模板
 * @param fileZh 中文
 * @param fileEn 英文
 */
export function downloadExcel(fileZh, fileEn) {
    let lan = getSysLanguage();
    if (lan === 'zh-CN') {
        window.open(HTTP_URL_API + '/static/excel/zh/' + fileZh);
    } else {
        window.open(HTTP_URL_API + '/static/excel/en/' + fileEn);
    }
}

/**
 * 将文本中HTML标签过滤获取纯文本
 * @param html
 * @returns {string|string}
 */
export function stripHtmlTags(html) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

/**
 * 表格导入校验
 */
export function importRules(file) {
    let isRules = true;
    if (!file) {
        ElMessage.error(this.$t('excel.importTips'))
        isRules  = false;
    }
    // 获取文件后缀
    const suffix = file.name.split('.').pop();
    if (suffix !== 'xlsx' && suffix !== 'xls') {
        ElMessage.error(this.$t('excel.importError'))
        isRules  = false;
    }
    const size = file.size;
    if (size >  10 * 1024 * 1024) {
        ElMessage.error(this.$t('excel.maxlog'))
        isRules  = false;
    }
    return isRules;
}
