import {getSysLanguage} from "@/util/system";

/**
 * 自定义指令: 根据语言环境显示或者不显示元素
 * 使用:
 * v-languageShow="'zh-CN'" / 表示中文语言环境下显示, 否则不显示
 * v-languageShow="'en-US'" /  表示英文语言环境下显示, 否则不显示
 */
export default {
    mounted(el, inding) {
        // 判断当前语言环境
        const sysLanguage = getSysLanguage();
        if (sysLanguage !== inding.value) {
            // 删除元素
            el.parentNode.removeChild(el)
        }
    },
};
